#contact-form , #contact-quote{
  padding-bottom: 8%;
  h1{
    font-family: 'Roboto-Ligth';
    color: #1a1446!important;
  }
  h4{
    font-family: 'Roboto-Ligth';
    color: #1a1446!important;
  }
  label{
    font-family: 'Roboto-Bold';
    color: #1a1446!important;
  }

  .form-control{
    padding: 16px;
    border-width: 0;
    border-radius: 0;
    color: #999;
    background-color: #eee;
    font-size: 14px;
  }
  .input-group-text{
    background-color: #eee!important;
    border-width: 0;
    border-radius: 0;
  }
  select{
    padding: 0px!important;
  }
  .react-tel-input{
    .form-control{
      width: 100%!important;
      padding-left: 50px;
    }
  }

  .btn-send{
    font-family: 'Roboto-Bold';
    color: #1a1446!important;
    border-width: 0px!important;
    border-color: #4093a5;
    border-radius: 20px;
    font-size: 16px;
    margin-left: 3px;
    margin-right: 3px;
    background-color: #99e5ea;
    box-shadow: 0px 3px 0px 0px #4093a5;
    transition: color 300ms ease 0ms,background-color 300ms ease 0ms,border 300ms ease 0ms;

  }

  .btn-send:hover{
    background-color: #007788!important;
    box-shadow: 0px 3px 0px 0px #1e2045;
    color: white!important;
  }

  .btn-back{
    font-family: 'Roboto-Bold';
    color: #00768b!important;
    border-width: 0px!important;
    border-color: #ffffff;
    border-radius: 20px;
    font-size: 16px;
    margin-left: 3px;
    margin-right: 3px;

    background-color: #ffffff;
    box-shadow: 0px 3px 0px 0px #4093a5;
    transition: color 300ms ease 0ms,background-color 300ms ease 0ms,border 300ms ease 0ms;

  }

  .btn-back:hover{
    background-color: #f5f5f5!important;
    box-shadow: 0px 3px 0px 0px #1e2045;
    color: #191745!important;
  }
  .invalid-feedback{
    font-family: 'Roboto-Ligth';
    display: block!important;
  }
  #recaptcha{
    padding-top: 15px;
  }

  .alert-heading{
    font-family: 'Roboto-Ligth';
  }
  .msg-heading{
    font-family: 'Roboto-Regular';

  }

}

.modal-title{
  font-family: 'Roboto-Regular';
  color: #1a1446!important;
}
.modal-text{
  font-family: 'Roboto-Ligth';
}

.btn-modal{
  font-family: 'Roboto-Bold';
  color: #1a1446!important;
  border-width: 0px!important;
  border-color: #4093a5;
  border-radius: 20px!important;
  font-size: 16px;

  background-color: #99e5ea!important;
  box-shadow: 0px 3px 0px 0px #4093a5;
  transition: color 300ms ease 0ms,background-color 300ms ease 0ms,border 300ms ease 0ms;
}
.btn-modal:hover{
  background-color: #007788!important;
  box-shadow: 0px 3px 0px 0px #1e2045;
  color: white!important;
}
