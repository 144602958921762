.btn-ramos{
  width: 100%;
  padding: 16px;
  border-width: 0;
  border-radius: 0;
  color: #999!important;
  background-color: #eee!important;
  font-size: 14px;
  text-align: left;
}
.modal-backdrop{
  background-color: transparent!important;
}

#cotizador{
  .politica-priv{
    text-align: justify-all;
  }
}