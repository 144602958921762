.list-products{
  position: absolute;
  z-index: 10;
  width: 98%;
  border-radius: 0!important;
  .card-header{
    font-family: Roboto-Bold;
  }
  .list-group-item{
    font-family: Roboto-Regular;
    cursor: pointer;
  }

  .list-group-item:hover{
    background-color: #0061F5;
    color: white;
  }

}