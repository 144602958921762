#contact-form {
  .politica-priv {
    padding-left: 12px;
    padding-right: 12px;
    text-align: justify-all;
  }
}
.modal-backdrop {
  background-color: transparent !important;
}

#cv {
  height: 10rem;
  width: 300rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  color: blue;
  border: dashed 1px rgb(129, 124, 124);
}
#centrar {
  display: flex;
  justify-content: center;
}